import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import DeleteIcon from "../../assets/images/delete.svg";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface propsData {
  isShow: any;
  isClose: any;
}


const DeleteModal = (props: propsData) => {
  const navigate = useNavigate();
  const OnDelete = () => {
    WebService.postAPI({
      action: `delete-account`,
      id: "delete_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        typeof window !== "undefined" && localStorage.clear();
        navigate("/login");
      })
      .catch((e: any) => {});
  };
  
  return (
    <Modal
      show={props.isShow}
      onHide={props.isClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="custom-modal-box"
    >
      <Modal.Header closeButton className="p-0"></Modal.Header>
      <Modal.Body className="p-0">
        <Row>
          <Col lg={12} sm={12} className="pt-2 text-center">
            <img src={DeleteIcon} width={90} height={90} alt="Delete" />
            <h4 className="text-dark-blue font-24 font-bold">Are you sure?</h4>
            <p>Are you sure you want to delete your account?</p>
            <div>
              <button
                type="button"
                className="border-btn mx-2"
                onClick={props.isClose}
              >
                Cancel
              </button>
              <button type="button" className="fill-btn" onClick={OnDelete}>
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
