import { Dispatch, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Tab, Button, InputGroup } from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Identity from "../../assets/images/identity.svg";
import { TagsInput } from "react-tag-input-component";
import Qualifications from "../../assets/images/qualifications.svg";
import Expertise from "../../assets/images/expertise.svg";
import { Controller, useForm } from "react-hook-form";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
import { Label } from "../Common/Label/Label";
import SelfMultiSelect from "../SelfMultiSelect/SelfMultiSelect";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MeCall_Update, setDataInRedux } from "../../action/CommonAction";

interface propsData {
  data: any;
}

const MedicalDetailsTab = (props: propsData) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
    reset,
    register,
    setValue,
  } = useForm<any>({});
  const watchAllFields = watch();

  const [expertiseData, setExpertiseData] = useState<any>();
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();

  const [selectedQualification, setSelectedQualification] = useState<any>("");
  const [uploadedDocument, setUploadedDocument] = useState<any>();
  const [documentName, setDocumentName] = useState<any>();
  const [imageList, setImageList] = useState<any[]>([]);
  const [signatureList, setSignatureList] = useState<any>();
  const [deletedImageList, setDeletedImageList] = useState<any[]>([]);
  const [showStatus, setShowStatus] = useState<any>();

  useEffect(() => {
    getDocumentsList();
    getExpertiseData();
    setSignatureList(props.data?.info.signature);
    setShowStatus(props.data?.info.status);
    reset(props.data?.info);

    const QualificationArr: any = props.data?.info?.qualifications?.split(", ");
    setSelectedQualification(QualificationArr);

    const expertiseFill = props.data?.info.expertises;
    var array = [];
    for (let i in expertiseFill) {
      array.push(expertiseFill[i].expertise_id);
      setValue("expertises", array);
    }
  }, []);

  const getExpertiseData = () => {
    WebService.getAPI({
      action: `expertises`,
    })
      .then((res: any) => {
        let data = res.list;
        let temp: any[] = [];
        for (let i in data) {
          temp.push({ id: data[i].id, value: data[i].title });
        }

        setExpertiseData(temp);
      })
      .catch(() => {});
  };

  const getDocumentsList = () => {
    WebService.getAPI({
      action: `get-documents`,
    })
      .then((res: any) => {
        setImageList(res.list);
      })
      .then(() => {});
  };

  const ValidateDocument = (e: any) => {
    const { files } = e.target;
    if (files && files[0]) {
      var sizeInBytes = files[0].size;
      var sizeInKb = sizeInBytes / 1024;
      var sizeInMb = sizeInKb / 1024;
      if (sizeInMb > 2) {
        toast.warn("Image must be less than 2 MB!", { theme: "colored" });
        return;
      }
      var isImageExtensions = /(\.(jpg|jpeg|png|pdf|svg|docx|xlsx|xls))$/i;
      if (!isImageExtensions.test(files[0].name)) {
        toast.warn("Invalid File Type", { theme: "colored" });
        return;
      }
      const blob = URL.createObjectURL(files[0]);
      setUploadedDocument(files[0]);
      setDocumentName(blob);

      onUploadDocument(files[0]);
      if (files && files[0]) {
        imageList.push({ file: files[0], name: files[0].name });
        setImageList([...imageList]);
      }
    }
  };

  const onUploadDocument = (file: any) => {
    WebService.uploadAPI({
      action: `upload-document`,
      body: { file: file, name: file.name },
    })
      .then((res: any) => {
        toast.success(res.message);
        // Upload the next document recursively
      })
      .catch(() => {
        // Handle error if needed
        toast.error("Failed to upload document");
      });
  };

  const ValidateSignature = (e: any) => {
    const { files } = e.target;
    if (files && files[0]) {
      var sizeInBytes = files[0].size;
      var sizeInKb = sizeInBytes / 1024;
      var sizeInMb = sizeInKb / 1024;
      if (sizeInMb > 2) {
        toast.warn("Image must be less than 2 MB!", { theme: "colored" });
        return;
      }
      var isImageExtensions = /(\.(jpg|jpeg|png))$/i;
      if (!isImageExtensions.test(files[0].name)) {
        toast.warn("Invalid File Type", { theme: "colored" });
        return;
      }
      const blob = URL.createObjectURL(files[0]);
      // setFileName(blob);
      // setUploadedSignature(files[0]);
      setSignatureList(blob);

      onUploadSigntaure(files[0], blob);

      // onUploadSigntaure(files[0]);
    }
  };

  const onUploadSigntaure = (file: any, FileName: any) => {
    WebService.uploadAPI({
      action: `upload-signature`,
      body: { image: file },
    })
      .then((res: any) => {
        toast.success(res.message);
      })
      .catch(() => {});
  };

  // const onRemoveImage = (index: any) => {
  //   if (imageList && imageList.length > 0) {
  //     if (imageList[index] && imageList[index].image) {
  //       deletedImageList.push(imageList[index].uuid);
  //       setDeletedImageList([...deletedImageList]);
  //     }
  //     var temp: any = [];
  //     for (var i in imageList) {
  //       if (i != index) {
  //         temp.push(imageList[i]);
  //       }
  //     }
  //     setImageList(temp);
  //     imageList.splice(1, index);
  //   }
  // };

  const onRemoveImage = (id: any) => {
    WebService.deleteAPI({
      action: `delete-document/${id}`,
    })
      .then((res: any) => {
        toast.success(res.message);
        getDocumentsList();
      })
      .catch(() => {});
  };

  const onSubmitMedicalDetails = (data: any) => {
    const qualificationsString = selectedQualification?.join(", ");
    const newdata = {
      ...data,
      qualifications: qualificationsString ? qualificationsString : "",
    };
    WebService.postAPI({
      action: `set-clinic-profile`,
      body: newdata,
      id: "medical_save",
    })
      .then((res: any) => {
        toast.success(res.message);
        dispatch(
          setDataInRedux({ type: MeCall_Update, value: new Date().getTime() })
        );
      })
      .catch(() => {});
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitMedicalDetails)}>
        <Row>
          <Col ld={6} sm={6}>
            {/* identity_number*/}
            <div className="form-group mb-3">
              <label>{t("Doctor’s Identity Number")}</label>
              <Controller
                control={control}
                name="identity_number"
                rules={{
                  // minLength: {
                  //   value: 8,
                  //   message: t("Mobile should have at least 8 digits"),
                  // },
                  // maxLength: {
                  //   value: 10,
                  //   message: t("Mobile should have at most 10 digits"),
                  // },
                  // pattern: {
                  //   value: /^[1-9]{1}[0-9]{7,9}$/,
                  //   message: t("Please enter valid Identity Number"),
                  // },
                  required: true,
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group">
                      <span className="input-group-text border-end-0 text-secondary left-corner">
                        <img
                          src={Identity}
                          width={20}
                          height={20}
                          alt="Identity"
                        />
                      </span>
                      <input
                        // onKeyPress={HelperService.allowOnlyNumericValue}
                        type="text"
                        className="form-control border-start-0 ps-1"
                        placeholder={t("Doctor’s Identity Number")}
                        autoComplete="off"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={watchAllFields?.identity_number}
                      />
                    </div>
                    {errors.identity_number && (
                      <div className="login-error">
                        <Label
                          title={
                            errors.identity_number?.message ||
                            watchAllFields.identity_number
                              ? errors.identity_number?.message?.toString()
                              : t("Please Enter Identity Number")
                          }
                          modeError={true}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>

            <div className="form-group btn-file mb-3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t("Upload Documents")}</Form.Label>
                {/* <Form.Control
                  type="file"
                  onChange={(e: any) => ValidateDocument(e)}
                  disabled={showStatus === "Pending" ? true : false}
                  multiple
                /> */}
                <div className="input-group mb-2 custom-file-upload cursor-pointer">
                  <div className="file-name">Choose files to upload</div>
                  <label
                    className="input-group-text browse-files"
                    htmlFor="inputGroupFile02"
                  >
                    {" "}
                    Browse Files
                    <input
                      disabled={showStatus == "Pending" ? true : false}
                      multiple
                      type="file"
                      onChange={(e: any) => ValidateDocument(e)}
                    />
                  </label>
                </div>
              </Form.Group>
              {imageList &&
                imageList?.length > 0 &&
                imageList?.map((item: any, index: any) => {
                  return (
                    <div
                      key={index + new Date().getTime()}
                      style={{ position: "relative" }}
                    >
                      <span
                        className="cursor-pointer badge rounded-pill bg-prim float-start me-2"
                        style={{ background: "#0B1956", fontWeight: "normal" }}
                        onClick={() => onRemoveImage(item.id)}
                      >
                        {item.name}
                        <Trash
                          color="white"
                          size={16}
                          cursor={"pointer"}
                          className="ps-1"
                        />
                      </span>
                    </div>
                  );
                })}
            </div>
            <div style={{ clear: "both", overflow: "hidden" }}></div>

            <div className="form-group mb-3 pt-2 btn-file">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t("Upload Signature")} </Form.Label>
                {/* <Form.Control
                  type="file"
                  onChange={(e: any) => ValidateSignature(e)}
                  disabled={showStatus === "Pending" ? true : false}
                /> */}
                <div className="input-group mb-2 custom-file-upload cursor-pointer">
                  <div className="file-name">Choose files to upload</div>
                  <label
                    className="input-group-text browse-files"
                    htmlFor="inputGroupFile02"
                  >
                    {" "}
                    Browse Files
                    <input
                      disabled={showStatus === "Pending" ? true : false}
                      multiple
                      type="file"
                      onChange={(e: any) => ValidateDocument(e)}
                    />
                  </label>
                </div>
              </Form.Group>
              {signatureList && (
                <img
                  src={signatureList}
                  width={30}
                  height={30}
                  alt="Identity"
                />
              )}
            </div>
          </Col>
          <Col ld={6} sm={6}>
            {/* add qualification */}
            <div className="form-group mb-2 mt-3 position-relative tags-inputs">
              <label>{t("Add Qualifications")}</label>
              <br />
              <small>
                {t(
                  "Note:Type your text and press 'Enter' to add it to your list"
                )}
              </small>
              <Controller
                control={control}
                name="qualifications"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <div className="form-control" style={{ height: "auto" }}>
                    <TagsInput
                      value={selectedQualification}
                      // onChange={setSelectedQualification}
                      disabled={showStatus === "Pending" ? true : false}
                      onChange={(res: any) => {
                        onChange(res);
                        setSelectedQualification(res);
                      }}
                      name="qualifications"
                      // placeHolder={t("Enter Qualifications")}
                      placeHolder={
                        selectedQualification ? "" : t("Enter Qualifications")
                      }
                    />
                  </div>
                )}
              />
              {errors.qualifications && (
                <div className="login-error mt-2">
                  <Label title={"Enter Qualifications"} modeError={true} />
                </div>
              )}
            </div>

            <div className="form-group mb-2 mt-3 position-relative tags-inputs">
              <label>{t("Expertises")}</label>

              <InputGroup style={{ width: "auto !important" }}>
                <span
                  className="input-group-text text-secondary left-corner border-0 position-absolute p-0"
                  style={{
                    borderRadius: "0 !important",
                    left: "12px",
                    zIndex: "9",
                    top: "14px",
                    background: "transparent",
                  }}
                >
                  <img src={Expertise} width={20} height={20} />
                </span>
              </InputGroup>
              <Controller
                control={control}
                name="expertises"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <SelfMultiSelect
                    selectLimit={100000}
                    options={expertiseData}
                    // selected={[94, 92]}
                    selected={
                      watchAllFields.expertises ? watchAllFields.expertises : []
                    }
                    placeholder={t("Select Expertises")}
                    onChange={(e: any) => {
                      if (e && e.length) {
                        field.onChange(
                          e.map((item: any) => {
                            return item.id;
                          })
                        );
                      } else {
                        field.onChange([]);
                      }
                    }}
                  />
                )}
              />
              {errors.expertises && (
                <div className="login-error mt-2">
                  <Label title={t("Select Expertises")} modeError={true} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} sm={6} className="mx-auto">
            <div className="text-center pt-3">
              <button
                type="submit"
                className="btn btn-brand-1 w-100 rounded-pill"
                id="medical_save"
              >
                {t("Save")}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default MedicalDetailsTab;
